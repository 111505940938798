import "./ViewRedditPostFinder.scss";
import { useEffect, useState } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useParams } from "react-router-dom";

import Input from "components/Input/Input";
import Table from "components/Table/Table";
import { useQuery } from "@tanstack/react-query";
import { getRedditPostData, retryFn } from "../../utils/api";
import PageLoading from "../../components/PageLoading/PageLoading";
import PageLoadingError from "../../components/PageLoadingError/PageLoadingError";

interface RedditQueryResult {
    post_title: string;
    post_link: string;
    query: string;
    position:number;
}

export default function ViewRedditPostFinder() {
    // --------------------- REACT ROUTER PARAMS ---------------------
    const { RedditID } = useParams();

    // ---------------------- NON STATE CONSTANTS ----------------------
    const pageSizes = [100, 500, 1000];

    // ---------------------- STATES ----------------------
    const [queries, setQueries] = useState<RedditQueryResult[]>([]);
    const [searchBoxFilter, setSearchBoxFilter] = useState("");

    // ---------------------- QUERIES ----------------------
    const { isFetching, isError, error, data } = useQuery({
        queryKey: ["getRedditPostData", RedditID],
        queryFn: () => getRedditPostData(RedditID!),
        refetchOnWindowFocus: false,
        retry: retryFn,
    });

    // ---------------------- EFFECTS ----------------------
    useEffect(() => {
        if (data) {
            // Function to replace null/undefined with "N/A"
            const setDefaults = (item: RedditQueryResult): RedditQueryResult => ({
                post_title: item.post_title || "N/A",
                post_link: item.post_link || "N/A",
                query: item.query || "N/A",
                position: item.position ?? "N/A",
            });

            // Transform data
            const transformedQueries = data['data']['queries'].map(setDefaults);

            setQueries(transformedQueries);
        }
    }, [data]);

    // ---------------------- TABLE COLUMN DEFS ----------------------
    const columnHelper = createColumnHelper<RedditQueryResult>();
    const columnDefs: ColumnDef<RedditQueryResult, any>[] = [
        columnHelper.accessor("post_title", {
            header: "Reddit Post URL",
            cell: (info) => <a href={info.row.original.post_link} target="_blank" rel="noopener noreferrer">{info.getValue()}</a>,
        }),
        columnHelper.accessor((row) => row.position, {
            id: "position",
            header: "SERP Rank",
            cell: (info) => info.getValue(),
        }),
    ];

    // ==============================================================  
    // ---------------------- MAIN RENDER CODE ----------------------  
    // ==============================================================  
    if (isFetching) return <PageLoading />;

    if (isError) return <PageLoadingError message={error as unknown as string} />;

    return (
        <div className={"reddit-container"}>
            <div className={"table-controls"}>
                {/* -------------------------- SEARCH BOX -------------------------- */}
                <Input
                    type={"text"}
                    className={"table-searchbox"}
                    placeholder={"Search for Queries..."}
                    value={searchBoxFilter ?? ""}
                    onChange={(e) => {
                        setSearchBoxFilter(String(e.target.value));
                    }}
                />
            </div>
            <Table
                tableContentName={"Reddit Post Queries"}
                tableData={queries}
                columnDefs={columnDefs}
                pageSizes={pageSizes}
                initialPageSize={pageSizes[0]}
                noDataText={"No Reddit Post Data Found"}
                searchBoxValue={searchBoxFilter}
            />
        </div>
    );
}
