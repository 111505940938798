import "./ManageUser.scss";

import { useState } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Table from "components/Table/Table";
import Input from "components/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
export interface RedditQuery {
    id: number;
    query: string;
    limit: number;
    is_processing: boolean;
    created_at: string;
}

export default function RedditPostFinder(props: { reddit_post_finder_queries: Array<RedditQuery> }) {
    // ---------------------- NON STATE CONSTANTS ----------------------
    const pageSizes = [10, 25, 50, 100];

    // ---------------------- STATES ----------------------
    const [searchBoxFilter, setSearchBoxFilter] = useState("");
    const navigate = useNavigate();

    // ---------------------- TABLE COLUMN DEFS ----------------------
    const columnHelper = createColumnHelper<RedditQuery>();
    const columnDefs: ColumnDef<any, any>[] = [
        columnHelper.accessor((row) => row.query, {
            id: "Topic",
            header: "Topic",
            cell: (info) =>
                <NavLink to={`/view-reddit-post-finder/${info.row.original.id}`}>
                    {info.getValue()}
                </NavLink>,
            enableGlobalFilter: true,
        }),
        columnHelper.accessor((row) => row.limit, {
            id: "limit",
            header: "Limit",
            cell: (info) => info.getValue(),
            enableGlobalFilter: false,
        }),
        columnHelper.accessor((row) => row.created_at, {
            id: "created_on",
            header: "Created On",
            cell: (info) => {
                const date = new Date(info.getValue());
                return date.toLocaleString();
            },
            enableGlobalFilter: false,
        }),
    ];

    // ==============================================================  
    // ---------------------- MAIN RENDER CODE ----------------------
    // ==============================================================  
    return (
        <div className={"card w-100 mt-4"}>
            <div className={"card-header"}>
                <p className={"card-header-title"}>Reddit Post Projects</p>
            </div>
            <div className={"card-content"}>
                <div className={"content"}>
                    <div className={"all-users-container"}>
                        <div className={"table-controls"}>
                            {/* -------------------------- SEARCH BOX -------------------------- */}
                            <Input
                                type={"text"}
                                className={"table-searchbox"}
                                placeholder={"Search for Queries..."}
                                value={searchBoxFilter ?? ""}
                                onChange={(e) => {
                                    setSearchBoxFilter(String(e.target.value));
                                }}
                            />
                        </div>
                        <Table
                            tableContentName={"Reddit Post Queries"}
                            tableData={props.reddit_post_finder_queries}
                            columnDefs={columnDefs}
                            pageSizes={pageSizes}
                            initialPageSize={pageSizes[0]}
                            noDataText={"No Queries Found"}
                            searchBoxValue={searchBoxFilter}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
